import { z } from 'zod'

import {
  ApplicantStage,
  DisqualifiedReason,
  HiringStage,
  NotInterestedReason,
  TerminatedReason,
} from 'src/graphql/types/applicants'
import { Frequency } from 'src/graphql/types/automatedRules'
import { formatSnakeValue } from 'src/lib/formatters'

export const MAX_EXECUTIONS = [
  { label: '1 time', value: '1' },
  { label: '5 times', value: '5' },
  { label: '10 times', value: '10' },
  { label: '15 times', value: '15' },
  { label: '30 times', value: '30' },
  { label: '60 times', value: '60' },
  { label: '90 times', value: '90' },
]

export enum TRIGGER_DELAY_LABELS {
  MINUTE = 'minutes',
  HOUR = 'hours',
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
}

export enum FREQUENCY_LABELS {
  ONCE = 'One time',
  MINUTE = 'Every minute',
  HOURLY = 'Every hour',
  DAILY = 'Every day',
  WEEKLY = 'Every week',
  MONTHLY = 'Every month',
}

//FORM SETUP:
export const rulesSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Please include a title' })
    .max(100, { message: 'Rule title is too long' }),
  conditions: z
    .array(
      z
        .object({
          key: z.string().min(1, { message: 'A condition is required' }),
          value: z
            .string({ invalid_type_error: 'A condition is required' })
            .optional(),
        })
        .refine((data) => {
          //This ensures that only parent conditions are required
          if (
            (data.key === 'applicantStage' || data.key === 'callCount') &&
            data.value === ''
          ) {
            return false
          }
          return true
        })
    )
    .min(1, { message: 'At least one condition is required' }),
  actions: z
    .array(
      z.object({
        actionType: z
          .string({ invalid_type_error: 'An action is required' })
          .min(1, { message: 'An action is required' }),
      })
    )
    .min(1, { message: 'At least one action is required' }),
})

export function calculateExecutionLimit(frequency) {
  switch (frequency) {
    case Frequency.ONCE:
      return 1
    case Frequency.MINUTE: // 1 hour - 60 execution
      return 60
    case Frequency.HOURLY: // 1 week - 168 execution
      return 24 * 7
    case Frequency.DAILY: // 3 month - 90 execution
      return 90
    case Frequency.WEEKLY: // 1 year - 52 execution
      return 52
    case Frequency.MONTHLY: // 1 year - 12 execution
      return 12
    default:
      return 1
  }
}

export function convertFrequencyToUnits(frequency) {
  switch (frequency) {
    case Frequency.ONCE:
      return 'times'
    case Frequency.MINUTE:
      return 'minutes'
    case Frequency.HOURLY:
      return 'hours'
    case Frequency.DAILY:
      return 'days'
    case Frequency.WEEKLY:
      return 'weeks'
    case Frequency.MONTHLY:
      return 'months'
  }
}

export const CONDITIONS_KEYS = [
  {
    label: 'Applicant Stage',
    value: 'applicantStage',
  },
  { label: 'Hiring Stage', value: 'hiringStage' },
  { label: 'Call Count', value: 'callCount' },
  { label: 'Not Interested Reason', value: 'notInterestedReason' },
  { label: 'Disqualified Reason', value: 'disqualifiedReason' },
  { label: 'Terminated Reason', value: 'terminatedReason' },
  { label: 'Missed Outbound Call Count', value: 'missedOutboundCallCount' },
  { label: 'Job Listing', value: 'jobListingId' },
]

export const ADD_CONDITION_KEYS = [
  {
    label: 'Applicant Stage',
    value: 'applicantStage',
  },
  { label: 'Call Count', value: 'callCount' },
  { label: 'Job Listing', value: 'jobListingId' },
  { label: 'Missed Outbound Call Count', value: 'missedOutboundCallCount' },
]

export const ACTIONS_KEYS = [
  {
    label: 'Send SMS',
    value: 'SMS',
  },
  { label: 'Send documents for signing', value: 'DOCUMENT_TEMPLATE_PACKAGE' },
  { label: 'Request an order', value: 'GENERATE_ORDER' },
  { label: 'Assign a lesson', value: 'ASSIGN_TRAINING' },
  { label: 'Assign a course', value: 'ASSIGN_COURSE' },
]

export const mapApplicantStagetoReason = (conditionValue) => {
  switch (conditionValue) {
    case 'TERMINATED':
      return 'terminatedReason'
    case 'DISQUALIFIED':
      return 'disqualifiedReason'
    case 'NOT_INTERESTED':
      return 'notInterestedReason'
    case 'PROSPECT':
      return 'hiringStage'
  }
}

//This function nests subconditions under their parent conditions and preserves the index from the original list
export const convertConditions = (conditionsList) => {
  const parentConditions = conditionsList.filter(
    (condition) =>
      condition.key === 'applicantStage' ||
      condition.key === 'callCount' ||
      condition.key === 'missedOutboundCallCount' ||
      condition.key === 'jobListingId'
  )
  const subConditions = conditionsList.filter(
    (condition) =>
      condition.key === 'terminatedReason' ||
      condition.key === 'disqualifiedReason' ||
      condition.key === 'notInterestedReason' ||
      condition.key === 'hiringStage'
  )
  const result = parentConditions.map((condition) => {
    const subCondition = subConditions.find(
      (subCondition) =>
        subCondition.key === mapApplicantStagetoReason(condition.value)
    )
    return {
      key: condition.key,
      value: condition.value,
      index: conditionsList.indexOf(condition),
      subCondition: subCondition
        ? {
            key: subCondition.key,
            value: subCondition.value,
            index: conditionsList.indexOf(subCondition),
          }
        : null,
    }
  })
  return result
}

export const fetchConditionOptions = (condition, jobListings) => {
  const callCountOptions = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20'

  switch (condition) {
    case 'hiringStage': {
      return Object.keys(HiringStage).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'applicantStage': {
      return Object.keys(ApplicantStage).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'notInterestedReason': {
      return Object.keys(NotInterestedReason).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'disqualifiedReason': {
      return Object.keys(DisqualifiedReason).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'terminatedReason': {
      return Object.keys(TerminatedReason).map((stage) => ({
        label: formatSnakeValue(stage),
        value: stage,
      }))
    }
    case 'callCount': {
      return callCountOptions.split(',').map((option) => ({
        label: option,
        value: option,
      }))
    }
    case 'missedOutboundCallCount': {
      return callCountOptions.split(',').map((option) => ({
        label: option,
        value: option,
      }))
    }
    case 'jobListingId': {
      return jobListings.map((job) => ({
        label: job.title,
        value: job.jobListingId,
      }))
    }
  }
}
